<template>
	<div>
		<h2 v-if="horse">{{ $t("horse.tiers_secondaires") }}</h2>
		<div class="box">
			<CustomTable
                id_table="tiers_horse_tiers_secondaires"
                :items="filtredTiersSecondaires"
                :busy.sync="table_busy"
				:rawColumns="rawColumnNames"
                primaryKey="tiershorse_id"
				:hrefsRoutes="config_table_hrefs"
            />

			<ModalTiersSecondaire ref="modal-add-tiers-secondaire" @submit="reload"></ModalTiersSecondaire>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ContactMixin from '@/mixins/Contact.js'
	import FonctionMixin from '@/mixins/Fonction.js'
	import Navigation from '@/mixins/Navigation.js'
	import TiersMixin from '@/mixins/Tiers.js'

	export default {
		name: "TiersSecondaires",
		mixins: [ContactMixin, FonctionMixin, Navigation, TiersMixin],
		props: ['horse'],
		data() {
			return {
				tiers_secondaires: null,
				table_busy: true,
				tiers_horse_part: null,
				config_table_hrefs: {
					'tiers.tiers_rs': {
						routeUniqueName: 'tiersTableauBord',
						routeName: 'tiersTableauBord',
						params: {
							tiers_id: 'tiers.tiers_id'
						}
					}
				},
				rawColumnNames: ['contact_phones_number', 'tiers_fonction'],
				events_tab: {
                    'TableAction::goToAddTiersSecondaires': () => {
						this.addOrEdit()
                    },
					'TableAction::goToEditTiersSecondaires': (tiers_horse) => {
						this.addOrEdit(tiers_horse)
                    },
					'TableAction::goToDeleteTiersSecondaires': (tiershorse_ids) => {
                        this.deleteTiersSecondaires(tiershorse_ids)
                    }
                }
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				let temp = []

				temp[0] = await this.loadTiersHorseSecondaires(this.horse.horse_id)
				temp[0].horse_id = this.horse.horse_id
				this.tiers_secondaires = temp
				this.table_busy = false
			},
			async addOrEdit(tiers_horse) {
				let params = [{ horse: this.horse }]
				if(tiers_horse) {
					this.$refs['modal-add-tiers-secondaire'].open(params, tiers_horse)
				}
				else {
					this.$refs['modal-add-tiers-secondaire'].open(params, null)
				}
			},
			reload() {
				this.table_busy = true
                this.init_component()
            },
			deleteTiersSecondaires(tiershorse_ids) {
				this.invalidTiersSecondaires(tiershorse_ids)
				this.reload()
			}
		},
		computed: {
			filtredTiersSecondaires: function() {
				let tiersSecondairesFormated = []
				if(!this.tiers_secondaires) {
					return []
				}

				this.tiers_secondaires.forEach(tiers_secondaire => {
					const copy_temp = this.deppCloneObj(tiers_secondaire)
					for(let i = 0; i < copy_temp.length; i++) {
						let temp = {}
						if(tiers_secondaire[i].tierscontact) {
							temp = {...copy_temp[i], ...copy_temp[i].tierscontact.contact}
							temp.contact_phones_number = this.getArrayObjProperty(temp.phones, "phone_combine").join("<br>")
						}
						else {
							temp = {...copy_temp[i]}
							temp.contact_phones_number = null
						}
						temp.tiers_fonction = this.getTrad(temp.fonction.fonction_label)
						temp.horse_nom = tiers_secondaire.horse_nom
						temp.horse_id = tiers_secondaire.horse_id
						tiersSecondairesFormated.push(temp)
					}
				});

				return tiersSecondairesFormated
			}
		},
        components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ModalTiersSecondaire: () => import('@/components/Horse/ModalTiersSecondaire')
        }
	}
</script>